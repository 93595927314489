'use strict';

angular.module('cpformplastApp')
  .directive('statcard', () => ({
    templateUrl: 'components/stat-card/stat-card.html',
    restrict: 'E',
    controller: 'StatCardController',
    controllerAs: 'statcard',
    scope: {
      elements: '=elements',
      color: '@color',
      statTitle: '@statTitle'
    }
  }));
